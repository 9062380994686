<template>
  <div class="bodycont">
    <comHeader />
    <div class="infobanner"></div>
    <div class="infodiv">
      <navTitle :navData='navData' />
      <div class="infocont">
          <p class="tab_top"><span>基本信息</span></p>
          <table class="table_ullist">
            <tr>
              <td class="tab_odd" >姓名:</td>
              <td class="tab_value">{{dataInfo.Name}}</td>
              <td class="tab_even">性别:</td>
              <td class="tab_value">{{dataInfo.Sex==1?'男':'女'}}</td>
            </tr>
            <tr>
              <td class="tab_odd">出生日期:</td>
              <td class="tab_value">{{dataInfo.Age}}</td>
              <td class="tab_even"></td>
              <td class="tab_value"></td>
            </tr>
            <tr style="height:84px;">
              <td class="tab_odd" >简介:</td>
              <td class="tab_value" colspan="4" >{{dataInfo.Introduction}}</td>
            </tr>
            <tr>
              <td class="tab_odd">学科领域:</td>
              <td class="tab_value"> {{dataInfo.subjectList}}</td>
              <td class="tab_even">应用领域:</td>
              <td class="tab_value">{{dataInfo.applicationList}}</td>
            </tr>
            <tr>
              <td class="tab_odd">{{titleName}}研究方向:</td>
              <td class="tab_value">{{dataInfo.Research}}</td>
              <td class="tab_even">{{titleName}}联系方式:</td>
              <td class="tab_value">{{dataInfo.Tel}}</td>
            </tr>
            <tr>
              <td class="tab_odd">{{titleName}}邮箱:</td>
              <td class="tab_value">{{dataInfo.Email}}</td>
              <td class="tab_even">{{titleName}}地址:</td>
              <td class="tab_value">{{dataInfo.Area}}</td>
            </tr>
            <tr  v-if='nameType==1'>
              <td class="tab_odd">{{titleName}}最高学位:</td>
              <td class="tab_value">{{dataInfo.degree}}</td>
              <td class="tab_even">{{titleName}}职称:</td>
              <td class="tab_value">{{dataInfo.title}}</td>
            </tr>
             <tr  v-if='nameType==1'>
              <td class="tab_odd">{{titleName}}行业领域:</td>
              <td class="tab_value">{{dataInfo.field}}</td>
              <td class="tab_even">{{titleName}}学科专业:</td>
              <td class="tab_value">{{dataInfo.describe}}</td>
            </tr>
             <tr v-if='nameType==1'>
              <td class="tab_odd">{{titleName}}标签:</td>
              <td class="tab_value">{{dataInfo.tag}}</td>
              <td class="tab_even">{{titleName}}资源标签:</td>
              <td class="tab_value">{{dataInfo.label}}</td>
            </tr>
             <tr v-if='nameType==1'>
              <td class="tab_odd">{{titleName}}级别:</td>
              <td class="tab_value">{{dataInfo.deptId}}</td>
              <td class="tab_even"></td>
              <td class="tab_value"></td>
            </tr>
             <tr v-if='nameType==4'>
              <td class="tab_odd">{{titleName}}行业大类:</td>
              <td class="tab_value">{{dataInfo.industryCategory}}</td>
              <td class="tab_even">{{titleName}}产业细分:</td>
              <td class="tab_value">{{dataInfo.industrySegmentation}}</td>
            </tr>
             <tr v-if='nameType==4'>
              <td class="tab_odd">{{titleName}}标签:</td>
              <td class="tab_value">{{dataInfo.applicationList}}</td>
              <td class="tab_even">{{titleName}}专家资源标签:</td>
              <td class="tab_value">{{dataInfo.applicationList}}</td>
            </tr>
             <tr v-if='nameType==4'>
              <td class="tab_odd">{{titleName}}可提供资源/合作:</td>
              <td class="tab_value">{{dataInfo.resCooperationAvailable}}</td>
              <td class="tab_even">{{titleName}}企业荣誉:</td>
              <td class="tab_value">{{dataInfo.corporateHonor}}</td>
            </tr>
              <tr >
              <td class="tab_odd">{{titleName}}详情:</td>
              <td class="tab_value"  colspan="4">{{dataInfo.Detai}}</td>
            </tr>
          </table>
      </div>
    </div>
    <bNav />
  </div>

</template>

<script>
  import comHeader from '@/components/header.vue'
  import bNav from '@/components/bottomNav.vue'
  import navTitle from '@/components/navtitle.vue'
  import api from '@/config/api'
  import {
    getRequst,
    postRequest
  } from '@/api/index'
  export default {
    components: {
      comHeader,
      bNav,
      navTitle,

    },
    data() {
      return {
        isshow: false,
        textarea: '',
        navData: [],
        objectId: '',
        nameType: '',
        dataInfo:{},
        titleName:'',
      }
    },
    mounted() {
      this.navData = [{
          name: this.$route.query.name,
          url: this.$route.query.url,
        },
        {
          name: '详情页',
          url: 'teamDetails',
        },
      ]
      this.objectId = this.$route.query.id
      this.nameType = this.$route.query.name
      if (this.$route.query.name == '特聘专家') {
       this.nameType = 1
       this.titleName ='专家'
      } else if (this.$route.query.name == '科创机构') {
        this.nameType = 2
        this.titleName ='机构'
      } else if (this.$route.query.name== '创新团队') {
         this.nameType = 3
         this.titleName ='团队'
      }else if (this.$route.query.name== '园区企业') {
         this.nameType = 4
         this.titleName ='企业'
      }
      if (this.objectId) {
        this.getInfo()
      }
    },
    methods: {
      changeleave() {
        this.isshow = !this.isshow
      },
      //获取列表
      getInfo() {
        let data = {
          objectId: this.objectId
        }
        postRequest(api.teamselectById, data).then(res => {
          if (this.nameType ==1) {
            this.gettext(res.data)
          } else if (this.nameType == 2) {
            this.gettext2(res.data)
          } else if (this.nameType == 3) {
            this.gettext1(res.data)
          }else if (this.nameType == 4) {
            this.gettext3(res.data)
          }
        })
      },
      gettext(data) {
        this.dataInfo = {
          age:data.age,
          Age: data.expertsAge,
          Area:data.expertsArea,
          Detail: data.expertsDetail,
          Email: data.expertsEmail,
          Field: data.expertsField,
          Id:data.expertsId,
          Introduction:data.expertsIntroduction,
          Name:data.expertsName,
          Research: data.expertsResearch,
          Results: data.expertsResults,
          Sex: data.expertsSex,
          Tel: data.expertsTel,
          fieldId:data.fieldId,
          file: data.file,
          objectId:data.objectId,
           fieldName:data.fieldName,
           applicationList:data.applicationList,
           subjectList:data.subjectList,
           degree:data.degree,
           title: data.title,
           field:data.fieldList,
           describe:data.describeList,
           tag: data.tag,
           label:data.label,
            deptId:data.deptId,
        }
      },
      gettext1(data) {
        this.dataInfo = {
          Area:data.teamAdds,
          Detail: data.teamDetail,
          Email: data.teamEmail,
          Field: data.teamField,
          Id:data.teamId,
          Introduction:data.teamIntroduction,
          Name:data.teamName,
          Research: data.teamResearch,
          Results: data.teamResults,
          Tel: data.teamTel,
          fieldId:data.fieldId,
          file: data.file,
          objectId:data.objectId,
          fieldName:data.fieldName,
          applicationList:data.applicationList,
          subjectList:data.subjectList,
        }
      },
      gettext2(data) {
        this.dataInfo = {
          Area:data.organizationAdds,
          Detail: data.organizationDetail,
          Email: data.organizationEmail,
          Field: data.organizationField,
          Id:data.organizationId,
          Introduction:data.organizationIntroduction,
          Name:data.organizationName,
          Research: data.organizationResearch,
          Results: data.organizationResults,
          Sex: data.organizationSex,
          Tel: data.organizationTel,
          fieldId:data.fieldId,
          file: data.file,
          objectId:data.objectId,
          fieldName:data.fieldName,
          applicationList:data.applicationList,
          subjectList:data.subjectList,
        }
      },
      gettext3(data) {
        this.dataInfo = {
          Area:data.enterprisesAdds,
          Detail: data.enterprisesDetail,
          Email: data.enterprisesEmail,
          Field: data.enterprisesField,
          Id:data.enterprisesId,
          Introduction:data.enterprisesIntroduction,
          Name:data.enterprisesName,
          Research: data.enterprisesResearch,
          Results: data.enterprisesResults,
          Sex: data.enterprisesSex,
          Tel: data.enterprisesTel,
          fieldId:data.fieldId,
          file: data.file,
          objectId:data.objectId,
          fieldName:data.fieldName,
          applicationList:data.applicationList,
          subjectList:data.subjectList,
          industryCategory:data.industryCategoryList,
          industrySegmentation:data.industrySegmentation,
          resCooperationAvailable:data.resCooperationAvailable,
          corporateHonor:data.corporateHonor,

        }
      }


    }
  }
</script>

<style lang="less" scoped>
  .infodata{
    /deep/ p{
      margin-top: 20px;
    }
   /deep/ span{
    line-height: 30px;
   }
  }
  .infodiv {
    width:1190px;
    margin: 0 auto;
    min-height: 77vh  }

  .contimg {
    width: 300px;
    height: 300px;
  }

  .leftContent {
    width: 100%;
      margin: 0 auto;

    .counter-box {
      background: #fff;
      border-radius: 5px;
      position: relative;
      box-shadow: 0px 5px 14px 0 #ddd;
      color: #323232;
      margin-top: 20px;
    }

    .levelMess {
      width: 100%;
      overflow: hidden;
      margin-bottom: 20px;

      .levelTit {
        position: relative;
        height: 60px;

        p {
          width: 100%;
          height: 30px;
          line-height: 30px;
          margin: 20px 0;
          font-size: 22px;
          color: #323232;
          border-left: 6px solid #1277fd;
          padding-left: 26px;
          z-index: 2;
          vertical-align: top;
          position: absolute;
          left: 0;
          bottom: 0;
        }

        .colorBox {
          height: 10px;
          width: 68px;
          background: #c0d4fb;
          z-index: 1;
          position: absolute;
          left: 29px;
          bottom: 20px;
        }
      }
    }

    .counter-box {
      background: #fff;
      border-radius: 5px;
      position: relative;
      box-shadow: 0px 5px 14px 0 #ddd;
      color: #323232;
    }

    .showview {
      margin: 10px 30px;
    }

    .showview div[class*=col-sm] {
      color: #323232;
      font-size: 15px;
      padding: 8px 0;
    }

    .showview .title {
      color: #999;
    }

    .showview span {
      font-size: 15px;
      line-height: 25px;
    }
  }

  .tit {
    margin-bottom: 10px;
  }

  .messTable {

    img {
      width: 20px;
      height: 22px;
    }
  }

  .btnBox {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

  .ctitle {
    font-size: 16px;
    color: #000;
    margin-bottom: 6px;
  }

  .cbtn {
    float: right;
    margin-top: 10px;
  }

  .relate>div {
    border: 1px solid #ededed;
    margin-bottom: 40px;
  }

  .allTit {
    width: 100%;
    background: #f8f8f8;
    box-sizing: border-box;
    color: #000;
    font-size: 18px;
    height: 50px;
    line-height: 50px;
    font-weight: normal;
    padding: 0 20px;
  }

  .relate {
    width: 23%;
  }

  .relate ul {
    padding: 20px;
    box-sizing: border-box;
    overflow: hidden;
    padding-top: 0;
  }

  .infocont {
   
  }

  .picUrl {
    img {
      width: 110px;
      height: 110px;
      border-radius: 50%;
    }

    width: 110px;
    height: 110px;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    top: 20px;
    right: 20px;
    text-align: center;
  }

  .bassMess {
    position: relative;
    background: #fff;
    padding: 24px;
    border-top: 6px solid #0e86d1;
    box-shadow: 0 0 20px #ddd;
    overflow: hidden;
    border-radius: 0 0 5px 5px;
    min-height:200px;

    h5 {
      color: #333;
      font-size: 24px;
      padding-bottom: 10px;
    }

    p {
      color: #333;
      font-size: 15px;
      padding: 8px 0;
    }
  }
  .desc{
    padding: 20px;
    .title{
      margin-bottom: 20px;

    }
  }
 .tab_top{
   height:34px;
   line-height: 34px;
   border-bottom:2px #206CCF solid;
   span{
     display: inline-block;
     background:#206CCF;
     color:#ffffff;
     padding:0 10px;
     border-radius: 5px 5px 0 0;
   }
 }
 .infobanner{
   background: url('../../assets/images/banner/banner1.png') center center no-repeat;
   width: 100%;
   height:250px;
   background-size:cover;
 }
.table_ullist{
  width:100%;
  tr{
    height:44px;
    td{
      border:1px #C3E7FE solid;
      line-height: 44px;
      padding-left: 10px;
    }
    .tab_odd{
      width:140px;
      background: #E8F3FF;
      text-align: right;
      padding-right: 5px;
    }
    .tab_even{
      width:140px;
      background: #E8F3FF;
      text-align: right;
      padding-right: 5px;
    }
  }
}
</style>
